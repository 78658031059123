<template>
  <div>
    <van-field label="序列号" v-model="szr7_Step2.serialNumber">
      <template #right-icon>
        <van-tag type="warning">{{jieMa}}</van-tag>
      </template>
    </van-field>
    <van-cell title="3.3V">
      <template #right-icon>
        <van-checkbox v-model="szr7_Step2.sanDianSan" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="时间5S抽检">
      <template #right-icon>
        <van-checkbox v-model="szr7_Step2.shiJian5" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="位移值">
      <template #right-icon>
        <van-checkbox v-model="szr7_Step2.weiYi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="灯光开关">
      <template #right-icon>
        <van-checkbox v-model="szr7_Step2.dengGuangKaiGuan" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="温度显示">
      <template #right-icon>
        <van-checkbox v-model="szr7_Step2.wenDuXianShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="温控状态">
      <template #right-icon>
        <van-checkbox v-model="szr7_Step2.wenKongZhuangTai" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="电磁铁状态">
      <template #right-icon>
        <van-checkbox v-model="szr7_Step2.dianCiTieZhuangTai" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-field label="备注" v-model="szr7_Step2.remark"> </van-field>
    <div class="footer">
      <van-button type="info" v-show="$parent.hasPower_Step2" round block :disabled="$parent.szr7_SignNumber.status!=0" @click="handleSave">保存</van-button>
      <van-button type="danger" v-if="$parent.szr7_SignNumber.status==2" round block @click="$parent.cancel_void_SignNumber()">取消作废</van-button>
      <van-button type="danger" v-else-if="$parent.szr7_SignNumber.status!=1" round block @click="$parent.void_SignNumber()">作废</van-button>

    </div>
  </div>
</template>

<script>
export default {
  props: ["signNumber"],
  data() {
    return {
      szr7_Step2: {},
      jieMa: "",
    };
  },
  methods: {
    getSZR7_Step2() {
      let that = this;
      that.axios
        .post("/SZR7/GetSZR7_Step2", { onlyText: that.signNumber })
        .then(function (response) {
          that.szr7_Step2 = response.data.data;
        });
    },
    handleSave() {
      let that = this;
      that.axios
        .all([that.save_SZR7_Step2(), that.registerSerialNumber()])
        .then(
          that.axios.spread(function (response1, response2) {
            that.szr7_Step2 = response1.data.data;
            if (response1.data.code == 201) {
              that.$notify({ type: "warning", message: response1.data.msg });
            } else if (response2.data.code == 201) {
              that.$notify({ type: "warning", message: response2.data.msg });
            } else {
              that.$notify({ type: "success", message: response1.data.msg });
            }
            if (response2.data.code == 101) {
              that.jieMa = response2.data.msg;
            }
          })
        );
    },
    save_SZR7_Step2() {
      let that = this;
      return that.axios.post("SZR7/UpdateSZR7_Step2", that.szr7_Step2);
    },
    registerSerialNumber() {
      let that = this;
      return that.axios.post("Base_Device/Register", {
        productName: "SZR-7针入度",
        productId: 1069,
        serialNumber: that.szr7_Step2.serialNumber,
        deviceType: "ZRD010",
        companyName: "",
        ifPrint: false,
      });
    },
  },
  mounted() {
    let that = this;
    that.getSZR7_Step2();
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
</style>
<template>
  <div>
    <van-cell title="程序下载">
      <template #right-icon>
        <van-checkbox v-model="szr7_Step1.chengXuXiaZai" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="屏幕显示">
      <template #right-icon>
        <van-checkbox v-model="szr7_Step1.pingMuXianShi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="按键检测">
      <template #right-icon>
        <van-checkbox v-model="szr7_Step1.anJianJianCe" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-cell title="蜂鸣器">
      <template #right-icon>
        <van-checkbox v-model="szr7_Step1.fengMingQi" shape="square"></van-checkbox>
      </template>
    </van-cell>
    <van-field label="备注" v-model="szr7_Step1.remark"> </van-field>
    <div class="footer">
      <van-button type="info" v-show="$parent.hasPower_Step1" :disabled="$parent.szr7_SignNumber.status!=0" round block @click="save_SZR7_Step1">保存</van-button>
      <van-button type="danger" v-if="$parent.szr7_SignNumber.status==2" round block @click="$parent.cancel_void_SignNumber()">取消作废</van-button>
      <van-button type="danger" v-else-if="$parent.szr7_SignNumber.status!=1" round block @click="$parent.void_SignNumber()">作废</van-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ["signNumber"],
  data() {
    return {
      szr7_Step1: {},
    };
  },
  methods: {
    getSZR7_Step1() {
      let that = this;
      that.axios
        .post("/SZR7/GetSZR7_Step1", { onlyText: that.signNumber })
        .then(function (response) {
          that.szr7_Step1 = response.data.data;
        });
    },
    save_SZR7_Step1() {
      let that = this;
      that.axios
        .post("SZR7/UpdateSZR7_Step1", that.szr7_Step1)
        .then(function (response) {
          that.szr7_Step1 = response.data.data;
          that.$notify({ type: "success", message: response.data.msg });
        });
    },
  },
  mounted() {
    let that = this;
    that.getSZR7_Step1();
  },
};
</script>

<style scoped>
.footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
</style>